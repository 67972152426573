// 商品相关
const productRoutes = [
  {
    path: '/product',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/product2/:id',
        name: 'productMain2',
        component: () => import('@/views/product/details/index2.vue'),
        meta: {
          cnName: '产品详情',
          enName: 'Product Detail'
        }
      },
      {
        path: '/product',
        name: 'productMain',
        component: () => import('@/views/product/details/index.vue'),
        meta: {
          cnName: '产品详情',
          enName: 'Product Detail'
        }
      },
      {
        path: '/productList',
        name: 'productList',
        component: () => import('@/views/product/list/index.vue'),
        meta: {
          cnName: '产品列表',
          enName: 'Product List'
        }
      },
      {
        path: '/cart',
        name: 'CART',
        component: () => import('@/views/cart/index.vue'),
        meta: {
          cnName: '购物车',
          enName: 'Cart'
        }
      }
    ]
  }
]

export default productRoutes
