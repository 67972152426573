import api from '@/api/request'

// 支付系列接口

// 提交订单-货到付款
export function getPayments(params) {
  return api.put(`/services/order/api/payment/end`, params)
}

// 提交订单-货到付款【弃用】
export function getPayments2(params) {
  return api.post(`/services/payment/api/payments`, params)
}

// 提交订单-银行转账
export function getBankAccount() {
  return api.get(`/services/order/api/bank/account`)
}

// java整合其他在线支付方式
export function paymentOrder(params) {
  return api.post(`/services/payment/api/payment/order`, params)
}

// easypaisa支付
export function easypaisaPayment(params) {
  return api.post(`${process.env.VUE_APP_PAYMENT_API}api/v1/public/service/payment/easypaisa`, params, {
    isDoEncrypt: true
  })
}

export function payfastPayment(params) {
  return api.post(`${process.env.VUE_APP_PAYMENT_API}api/v1/public/service/payment/payfast`, params, {
    isDoEncrypt: true
  })
}


/**
 * 创建退款申请
 * @param data
 * @returns {*}
 */
export function createRequestRefundReq(data) {
  return api.post('/services/payment/api/refund-applies',data)
}


/**
 * 根据订单ID查询订单明细详情
 * @param id
 * @returns {*}
 */
export function getOrderDetailByIdReq(id) {
  return api.get('/services/order/api/purchase-orders/with-expand/' + id)
}

export function paymentMethods(data) {
  return api.get('/services/order/api/payment/new/methods', data)
}